<template>
  <div class="auth-code-wrap">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
      <p>1. 按 F12 打开轻流控制台</p>
      <p>2. 切换到 Network 面板</p>
      <p>3. 刷新一下页面，让新的请求出现在 Network 中</p>
      <p>4. 点击 XHR</p>
      <p>5. 随便找一个接口，例如 message?pageSize...</p>
      <p>6. 点击 Headers</p>
      <p>7. 找到 Request Headers，翻看参数，找到 token</p>
      <p>8. 如果没找到就换个接口试试（重复第5步）</p>
      <p>
        注：建议每次更新 token 之前退出轻流并重新登录，这个时候的 token
        是全新的，大概维持半个月
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          我知道了
        </el-button>
      </span>
    </el-dialog>

    <el-card v-loading="saveTokenLoading" class="box-card">
      <el-input
        v-model="token"
        style="width: 320px; margin-right: 20px;"
        placeholder="请输入新的Token"
      />
      <el-button :loading="saveTokenLoading" @click="saveToken" type="primary">
        保存
      </el-button>

      <el-button
        @click="findToken"
        style="float: right;"
        icon="el-icon-question"
      >
        怎么找Token？
      </el-button>
    </el-card>

    <el-card style="margin-top: 20px;" class="box-card">
      <div slot="header" class="clearfix">
        <span>授权码</span>
        <span>
          <el-button
            @click="handelSaveBtn"
            :loading="btnLoading"
            style="float: right; padding: 3px 0; margin-left: 20px;"
            type="text"
            icon="el-icon-circle-plus-outline"
            >生成授权码</el-button
          >
          <el-button
            @click="changeCurrentPage(1)"
            :loading="tableLoading"
            style="float: right; padding: 3px 0;"
            type="text"
            icon="el-icon-refresh"
            >刷新列表</el-button
          >
          <div class="clear: both;"></div>
        </span>
      </div>

      <div>
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          stripe
          style="width: 100%"
        >
          <el-table-column prop="authCode" label="授权码"> </el-table-column>
          <el-table-column prop="inventory" label="点数"> </el-table-column>
          <el-table-column prop="authTime" label="授权时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="danger"
                @click="deleteAuthCode(scope.row)"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-wrap">
          <el-pagination
            background
            @current-change="changeCurrentPage"
            layout="total, prev, pager, next"
            :current-page="pageNum"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAuthCodes, saveAuthCode, deleteAuthCode } from '@/api/authCode';
import { updateToken } from '@/api/order';

export default {
  data() {
    return {
      btnLoading: false,
      tableData: [],
      pageNum: 1,
      total: 0,
      tableLoading: false,
      token: '',
      dialogVisible: false,
      saveTokenLoading: false,
    };
  },
  mounted() {
    this.getAuthCodes();
  },
  methods: {
    // 保存token
    saveToken() {
      let self = this;
      self.saveTokenLoading = true;
      updateToken({ id: self.token })
        .then((res) => {
          console.log(res);
          self.$message.success('Token修改成功');
          self.token = '';
          self.saveTokenLoading = false;
        })
        .catch((err) => {
          self.$message.error(err);
          self.saveTokenLoading = false;
        });
    },
    // 怎么找到token
    findToken() {
      this.dialogVisible = true;
    },
    // 点击授权按钮
    handelSaveBtn() {
      const self = this;
      self
        .$prompt('请输入点数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^-?[1-9]+[0-9]*$/,
          inputErrorMessage: '请输入合法的数字',
        })
        .then(async ({ value }) => {
          if (value > 999999)
            return self.$message.error('超过限制，最多999999个点！');
          self.saveAuthCode(value || 10);
        })
        .catch(() => {});
    },
    // 生成授权码
    saveAuthCode(inventory) {
      const self = this;
      self.btnLoading = true;
      saveAuthCode({
        inventory,
      })
        .then((res) => {
          self.btnLoading = false;
          self.changeCurrentPage(1);
          self.$message.success(`生成授权码成功：${res.authCode}`);
        })
        .catch((err) => {
          self.$message.error(err);
          self.btnLoading = false;
        });
    },
    // 切换分页
    changeCurrentPage(val) {
      this.pageNum = val;
      this.getAuthCodes();
    },
    // 获取授权码列表
    getAuthCodes() {
      const self = this;
      self.tableLoading = true;
      getAuthCodes({
        pageNum: self.pageNum,
        pageSize: 10,
      })
        .then((res) => {
          self.tableData = res.content;
          self.total = res.total;
          self.tableLoading = false;
        })
        .catch(() => {
          self.tableLoading = false;
        });
    },
    // 删除授权码
    deleteAuthCode(row) {
      const self = this;
      this.$confirm('是否永久删除该授权码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = self.$vs.loading();
          deleteAuthCode({
            authCode: row.authCode,
          })
            .then(() => {
              self.$message.success(`授权码${row.authCode} 已被删除`);
              self.getAuthCodes();
              loading.close();
            })
            .catch(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 30px;
}

.auth-code-wrap {
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
}
.pagination-wrap {
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}
</style>
