import request from "@/utils/request";
import baseUrl from "@/utils/url";

export const getOrderProgress = params => {
  return request("get", baseUrl.express + "/order/progress", params);
};

export const updateToken = params => {
  return request("get", baseUrl.express + "/order/updateToken", params);
};
